<template>
  <v-row>
    <v-col cols="12" class="py-0 px-0">
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        :options.sync="options"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.name="{ item }">
          <span class="form-link" @click="open(item)">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:item.ip="{ item }">
          {{ item.ip_address }}
        </template>
        <template v-slot:item.vendor="{ item }">
          <img
            :src="
              $vuetify.theme.dark
                ? vendorImage(item.vendor, true)
                : vendorImage(item.vendor, false)
            "
            :alt="item.vendor"
            class="vendor-img"
          />
        </template>
        <template v-slot:item.onlinestatus="{ item }">
          <v-icon :color="statusColor(item.onlinestatus)" small>
            fas fa-circle
          </v-icon>
        </template>
        <template v-slot:item.ruleset_status="{ item }">
          <v-icon :color="statusColor(item.ruleset_status)" small>
            fas fa-circle
          </v-icon>
        </template>

        <template v-slot:item.auditor_types="{ item }">
          <span v-if="item.auditor_types.length === 0">-</span>
          <div v-else>
            <v-chip
              v-for="(name, index) in item.auditor_types"
              :key="index"
              x-small
              color="neutral-4"
              class="mr-1 text-white"
            >
              {{ name }}
            </v-chip>
          </div>
        </template>

        <template v-slot:item.ip_panorama="{ item }">
          {{ item.ip_panorama ? item.ip_panorama : "-" }}
        </template>
        <template v-slot:item.security_policy_count="{ item }">
          {{ auditorMode(item.security_policy_count) }}
        </template>
        <template v-slot:item.sw_version="{ item }">
          {{ item.sw_version ? item.sw_version : "-" }}
        </template>
        <template v-slot:item.model="{ item }">
          {{ item.model ? item.model : "-" }}
        </template>
        <template v-slot:item.last_commit="{ item }">
          <div style="display: flex; flex-direction: row">
            <div style="font-weight: bold; margin-right: 10px; width: 80px">
              Id:
            </div>
            <div style="flex: 1">{{ item.last_commit_id }}</div>
          </div>
          <div style="display: flex; flex-direction: row">
            <div style="font-weight: bold; margin-right: 10px; width: 80px">
              Date:
            </div>
            <div style="flex: 1">{{ item.last_commit_date }}</div>
          </div>
          <div style="display: flex; flex-direction: row">
            <div style="font-weight: bold; margin-right: 10px; width: 80px">
              User:
            </div>
            <div style="flex: 1">{{ item.last_commit_user }}</div>
          </div>
          <div style="display: flex; flex-direction: row">
            <div style="font-weight: bold; margin-right: 10px; width: 80px">
              Last check:
            </div>
            <div style="flex: 1">{{ item.last_commit_check }}</div>
          </div>
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description ? item.description : "-" }}
        </template>
        <template v-slot:item.edl_list="{ item }">
          <span v-if="item.edl_list.length === 0">-</span>
          <div v-else>
            <v-chip
              v-for="(name, index) in item.edl_list"
              :key="index"
              x-small
              color="neutral-4"
              class="mr-1 text-white"
            >
              {{ name }}
            </v-chip>
          </div>
        </template>
        <template v-slot:item.zones_interfaces="{ item }">
          <span v-if="item.zones_interfaces.length === 0">-</span>
          <div v-else>
            <v-chip
              v-for="(name, index) in item.zones_interfaces"
              :key="index"
              x-small
              color="neutral-4"
              class="mr-1 text-white"
            >
              {{ name }}
            </v-chip>
          </div>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-menu
            v-model="item.deleteMenu"
            :close-on-content-click="false"
            rounded
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                v-if="item.deletable"
                color="bad-2"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon x-small>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list width="350">
                <v-list-item>
                  <v-list-item-content class="pb-0">
                    <v-list-item-title
                      >Are you sure you want to delete this Firewall?
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-right mt-2">
                      <v-btn
                        rounded
                        text
                        color="tertiary"
                        small
                        class="text-capitalize"
                        @click="cancelDelete(item)"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        text
                        color="bad-2"
                        small
                        class="text-capitalize"
                        @click="deleteFirewall(item)"
                      >
                        Delete
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            v-if="item.vendorattribute_id !== 3"
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <td
                      v-if="expandItem.value === 'name'"
                      width="150"
                      class="pl-3"
                    >
                      Name
                    </td>
                    <td v-if="expandItem.value === 'name'">
                      {{ item.name }}
                    </td>

                    <td
                      v-if="expandItem.value === 'ip_address'"
                      width="150"
                      class="pl-3"
                    >
                      IP address
                    </td>
                    <td v-if="expandItem.value === 'ip_address'">
                      {{ item.ip_address }}
                    </td>

                    <td
                      v-if="expandItem.value === 'vendor'"
                      width="150"
                      class="pl-3"
                    >
                      Vendor
                    </td>
                    <td v-if="expandItem.value === 'vendor'">
                      <img
                        :src="vendorImage(item.vendor)"
                        :alt="item.vendor"
                        class="vendor-img"
                      />
                    </td>

                    <td
                      v-if="expandItem.value === 'ruleset_status'"
                      width="150"
                      class="pl-3"
                    >
                      Ruleset Status
                    </td>
                    <td v-if="expandItem.value === 'ruleset_status'">
                      <v-icon :color="statusColor(item.ruleset_status)" small>
                        fas fa-circle
                      </v-icon>
                    </td>

                    <td
                      v-if="expandItem.value === 'onlinestatus'"
                      width="150"
                      class="pl-3"
                    >
                      Status
                    </td>
                    <td v-if="expandItem.value === 'onlinestatus'">
                      <v-icon :color="statusColor(item.onlinestatus)" small>
                        fas fa-circle
                      </v-icon>
                    </td>

                    <td
                      v-if="expandItem.value === 'delete'"
                      width="150"
                      class="pl-3"
                    >
                      Delete
                    </td>
                    <td v-if="expandItem.value === 'delete'">
                      <v-menu
                        v-if="item.deletable"
                        v-model="item.deleteMenu"
                        :close-on-content-click="false"
                        rounded
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            icon
                            color="bad-2"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-icon x-small>fas fa-trash-alt</v-icon>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-list width="350">
                            <v-list-item>
                              <v-list-item-content class="pb-0">
                                <v-list-item-title
                                  >Are you sure you want to delete this
                                  Firewall?
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-right mt-2">
                                  <v-btn
                                    rounded
                                    text
                                    color="tertiary"
                                    small
                                    class="text-capitalize"
                                    @click="cancelDelete(item)"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    rounded
                                    text
                                    color="bad-2"
                                    small
                                    class="text-capitalize"
                                    @click="deleteFirewall(item)"
                                  >
                                    Delete
                                  </v-btn>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </td>

                    <td
                      v-if="expandItem.value === 'sw_version'"
                      width="150"
                      class="pl-3"
                    >
                      Version
                    </td>
                    <td v-if="expandItem.value === 'sw_version'">
                      {{ item.sw_version }}
                    </td>
                    <td
                      v-if="expandItem.value === 'model'"
                      width="150"
                      class="pl-3"
                    >
                      Model
                    </td>
                    <td v-if="expandItem.value === 'model'">
                      {{ item.model }}
                    </td>
                    <td
                      v-if="expandItem.value === 'description'"
                      width="150"
                      class="pl-3"
                    >
                      Description
                    </td>
                    <td v-if="expandItem.value === 'description'">
                      {{ item.description }}
                    </td>

                    <td
                      v-if="
                        expandItem.value === 'ip_panorama' &&
                        item.vendorattribute_id === 1
                      "
                      width="150"
                      class="pl-3"
                    >
                      Panorama
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'ip_panorama' &&
                        item.vendorattribute_id === 1
                      "
                    >
                      {{ item.ip_panorama ? item.ip_panorama : "-" }}
                    </td>

                    <td
                      v-if="
                        expandItem.value === 'serial' &&
                        item.vendorattribute_id === 1
                      "
                      width="150"
                      class="pl-3"
                    >
                      Serial
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'serial' &&
                        item.vendorattribute_id === 1
                      "
                    >
                      {{ item.serial ? item.serial : "-" }}
                    </td>

                    <td
                      v-if="
                        expandItem.value === 'system_info' &&
                        item.vendorattribute_id === 1
                      "
                      width="150"
                      class="pl-3"
                    >
                      System Info
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'system_info' &&
                        item.vendorattribute_id === 1
                      "
                    >
                      <div class="d-flex justify-space-between">
                        <div class="w-50">
                          <span
                            v-for="(data, index) in item.system_info"
                            :key="index"
                          >
                            <div v-if="index < item.system_info.length / 2">
                              <b>{{ data.key }} </b>: {{ data.value }}<br />
                            </div>
                          </span>
                        </div>

                        <div class="w-50">
                          <span
                            v-for="(data, index) in item.system_info"
                            :key="index"
                          >
                            <div v-if="index > item.system_info.length / 2">
                              <b>{{ data.key }} </b>: {{ data.value }}<br />
                            </div>
                          </span>
                        </div>
                      </div>
                      <!--                      {{ item.system_info ? JSON.stringify(item.system_info) : '-' }}-->
                    </td>

                    <td
                      v-if="expandItem.value === 'security_policy_count'"
                      width="150"
                      class="pl-3"
                    >
                      Security Policy
                    </td>
                    <td v-if="expandItem.value === 'security_policy_count'">
                      {{ auditorMode(item.security_policy_count) }}
                    </td>

                    <td
                      v-if="expandItem.value === 'auditor_types'"
                      width="150"
                      class="pl-3"
                    >
                      Allowed Auditor Types
                    </td>
                    <td v-if="expandItem.value === 'auditor_types'">
                      <span v-if="item.auditor_types.length === 0">-</span>
                      <div v-else>
                        <v-chip
                          v-for="(name, index) in item.auditor_types"
                          :key="index"
                          x-small
                          color="neutral-4"
                          class="mr-1 text-white"
                        >
                          {{ name }}
                        </v-chip>
                      </div>
                    </td>

                    <td
                      v-if="expandItem.value === 'edl_list'"
                      width="150"
                      class="pl-3"
                    >
                      EDL Lists
                    </td>
                    <td v-if="expandItem.value === 'edl_list'">
                      <span v-if="item.edl_list.length === 0">-</span>
                      <div v-else>
                        <v-chip
                          v-for="(name, index) in item.edl_list"
                          :key="index"
                          x-small
                          color="neutral-4"
                          class="mr-1 text-white"
                        >
                          {{ name }}
                        </v-chip>
                      </div>
                    </td>

                    <td
                      v-if="expandItem.value === 'last_commit'"
                      width="150"
                      class="pl-3"
                    >
                      Last commit
                    </td>
                    <td v-if="expandItem.value === 'last_commit'">
                      <div style="display: flex; flex-direction: row">
                        <div
                          style="
                            font-weight: bold;
                            margin-right: 10px;
                            width: 80px;
                          "
                        >
                          Id:
                        </div>
                        <div style="flex: 1">{{ item.last_commit_id }}</div>
                      </div>
                      <div style="display: flex; flex-direction: row">
                        <div
                          style="
                            font-weight: bold;
                            margin-right: 10px;
                            width: 80px;
                          "
                        >
                          Date:
                        </div>
                        <div style="flex: 1">{{ item.last_commit_date }}</div>
                      </div>
                      <div style="display: flex; flex-direction: row">
                        <div
                          style="
                            font-weight: bold;
                            margin-right: 10px;
                            width: 80px;
                          "
                        >
                          User:
                        </div>
                        <div style="flex: 1">{{ item.last_commit_user }}</div>
                      </div>
                      <div style="display: flex; flex-direction: row">
                        <div
                          style="
                            font-weight: bold;
                            margin-right: 10px;
                            width: 80px;
                          "
                        >
                          Last check:
                        </div>
                        <div style="flex: 1">{{ item.last_commit_check }}</div>
                      </div>
                    </td>

                    <td
                      v-if="expandItem.value === 'zones_interfaces'"
                      width="150"
                      class="pl-3"
                    >
                      Zones
                    </td>
                    <td v-if="expandItem.value === 'zones_interfaces'">
                      <span v-if="item.zones_interfaces.length === 0">-</span>
                      <div v-else>
                        <v-chip
                          v-for="(name, index) in item.zones_interfaces"
                          :key="index"
                          x-small
                          color="neutral-4"
                          class="mr-1 text-white"
                        >
                          {{ name }}
                        </v-chip>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
          <td v-else :colspan="headers.length" class="expand-block">
            <v-simple-table class="synapsa-table inside-expanded">
              <template v-slot:default>
                <thead>
                  <tr style="background-color: transparent !important">
                    <th class="text-left">IP address</th>
                    <th class="text-left">Hostname</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">SW version</th>
                    <th class="text-left">Model</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="child in item.children" :key="child.id">
                    <td>{{ child.ip_address }}</td>
                    <td>{{ child.hostname }}</td>
                    <td>
                      <v-icon :color="statusColor(child.onlinestatus)" small>
                        fas fa-circle
                      </v-icon>
                    </td>
                    <td>{{ child.sw_version }}</td>
                    <td>{{ child.model }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],
      location: "",

      page: 1,
      pageTotalCount: 1,
      size: 1,
      order: null,
      by: null,
      options: {},

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== this.sizes.indexOf(this.tableSize)) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue.sortBy &&
          newValue.sortDesc &&
          newValue.sortBy.length > 0 &&
          newValue.sortDesc.length > 0
        ) {
          const order = newValue.sortDesc[0] ? "desc" : "asc";
          const by = newValue.sortBy[0];
          this.$emit("change", {
            page: this.page,
            size: this.sizes[this.size],
            order: order,
            orderBy: by,
          });
        }
      },
    },
  },
  created() {
    this.location = window.location.origin;
  },
  methods: {
    vendorImage(vendor, dark) {
      if (vendor === "Palo Alto") {
        return dark
          ? this.location + "/images/vendors/paloalto_white.png"
          : this.location + "/images/vendors/paloalto.png";
      } else if (vendor === "Fortinet") {
        return dark
          ? this.location + "/images/vendors/fortinet_white.png"
          : this.location + "/images/vendors/fortinet.png";
      } else if (vendor === "Fortinet FortiGate") {
        return dark
          ? this.location + "/images/vendors/fortinet_fortigate_white.png"
          : this.location + "/images/vendors/fortinet_fortigate.png";
      } else if (vendor === "Fortinet FortiManager") {
        return dark
          ? this.location + "/images/vendors/fortinet_fortimanager_white.png"
          : this.location + "/images/vendors/fortinet_fortimanager.png";
      } else if (vendor === "Checkpoint") {
        return dark
          ? this.location + "/images/vendors/checkpoint_white.png"
          : this.location + "/images/vendors/checkpoint.png";
      } else if (vendor === "Sophos") {
        return this.location + "/images/vendors/sophos.png";
      } else {
        return null;
      }
    },
    statusColor(status) {
      if (status === 1) {
        return "#87d05a";
      } else if (status === 2) {
        return "#e53355";
      } else {
        return "#c5c5c5";
      }
    },
    auditorMode(count) {
      if (!count) {
        return " - (refresh from Auditor task)";
      } else if (count < 1000) {
        return count + " (Auditor mode: Basic)";
      } else {
        return count + " (Auditor mode: Advanced)";
      }
    },
    open(item) {
      this.idAddForm = item.id;
      this.keywordAddForm = "firewall";
    },
    cancelDelete(item) {
      this.$emit("cancelDelete", item);
    },
    deleteFirewall(item) {
      this.$emit("deleteFirewall", item);
    },
  },
};
</script>
